<template>
  <v-container>
    <title-header :title="grantee.fantasyName" />
    <v-row>
      <v-col cols="12" lg="4">
        <info-person-pj
          v-if="grantee"
          title="Donatário"
          :person="grantee"
        ></info-person-pj>
      </v-col>
      <v-col cols="12" lg="8">
        <list-donation v-if="granteeId" :id="granteeId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from "vuex";

  import InfoPersonPJ from "@/modules/core/views/components/InfoPersonPJ";
  import ListDonation from "@/modules/stock/destination/views/components/ListDonation";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  export default {
    name: "DetailGranteePJ",
    components: {
      "info-person-pj": InfoPersonPJ,
      ListDonation,
      TitleHeader
    },
    data: () => ({
      granteeId: null,
      grantee: {}
    }),
    async mounted() {
      this.initializeGranteeId();
      await this.fetchDonator();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getGranteePJ"]),
      initializeGranteeId() {
        this.granteeId = parseInt(this.$route.params.id, 10);
      },
      async fetchDonator() {
        try {
          const { data } = await this.getGranteePJ(this.granteeId);
          this.grantee = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
