<template>
  <v-card class="table-page">
    <div class="px-10 pt-5 mb-6">
      <h2>{{ title }}</h2>
      <v-row v-if="person.fantasyName">
        <v-col cols="5" md="4">
          <span class="black-3--text">Empresa:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ person.fantasyName }}</span>
        </v-col>
      </v-row>
      <v-row v-if="person.cnpj">
        <v-col cols="5" md="4">
          <span class="black-3--text">CNPJ:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{
            person.cnpj | VMask(masks.cnpj)
          }}</span>
        </v-col>
      </v-row>
      <v-row v-if="person.email">
        <v-col cols="5" md="4">
          <span class="black-3--text">E-mail:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ person.email }}</span>
        </v-col>
      </v-row>
      <v-row v-if="phones">
        <v-col cols="5" md="4">
          <span class="black-3--text">Telefone(s):</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ phones }}</span>
        </v-col>
      </v-row>
      <v-row v-if="address">
        <v-col cols="5" md="4"
          ><span class="black-3--text">Endereço:</span></v-col
        >
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ address }}</span>
        </v-col>
      </v-row>
    </div>
    <template v-if="person.agent">
      <v-divider />
      <div class="px-10 pt-5 mb-6">
        <h2>Representante</h2>
        <v-row v-if="person.agent.name">
          <v-col cols="5" md="4">
            <span class="black-3--text">Nome:</span>
          </v-col>
          <v-col cols="7" md="8">
            <span class="black-1--text">{{ person.agent.name }}</span>
          </v-col>
        </v-row>
        <v-row v-if="person.agent.email">
          <v-col cols="5" md="4">
            <span class="black-3--text">E-mail:</span>
          </v-col>
          <v-col cols="7" md="8">
            <span class="black-1--text">{{ person.agent.email }}</span>
          </v-col>
        </v-row>
        <v-row v-if="agentPhones">
          <v-col cols="5" md="4">
            <span class="black-3--text">Telefone(s):</span>
          </v-col>
          <v-col cols="7" md="8">
            <span class="black-1--text">{{ agentPhones }}</span>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-card>
</template>

<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";

  export default {
    name: "InfoPersonPJ",
    mixins: [MasksMixin],
    props: {
      title: {
        type: String,
        required: true
      },
      person: {
        type: Object,
        required: true
      }
    },
    computed: {
      address() {
        const { address } = this.person;
        if (!address) return "";
        return parseAdressSingleLine(address);
      },
      phones() {
        const { phones } = this.person;
        if (!phones) return "";
        return phones.map(phone => this.parsePhone(phone)).join(", ");
      },
      agentPhones() {
        const { phones } = this.person?.agent;
        if (!phones || !phones.length) {
          return "";
        }

        return phones.map(phone => this.parsePhone(phone)).join(", ");
      }
    }
  };
</script>

<style lang="scss" scoped></style>
